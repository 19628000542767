import student from './student'
import recruit from './recruit'
import finance from './finance'
import staff from './staff'
import teach from './teach'
export default {
    ...recruit, // 招生菜单  
    ...student, // 学生菜单  
    ...finance, // 财务菜单
    ...staff, // 学员菜单
    ...teach // 教学菜单
}