const staff = [
    {
        path: '/home/staff/staff1/1',
        name: 'Staff1-1',
        component: () => import('../views/staff/staff1/1.vue')
    },
    {
        path: '/home/staff/staff2/1',
        name: 'Staff2-1',
        component: () => import('../views/staff/staff2/1.vue')
    },
    {
        path: '/home/staff/staff3/1',
        name: 'Staff3-1',
        component: () => import('../views/staff/staff3/1.vue')
    }
]

export default staff