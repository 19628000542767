export default {
    staff: [{
        text: "一、员工资料",
        path: "/home/staff/staff1/1#员工资料",
        children: [{
            text: "1.员工资料查询",
            path: "/home/staff/staff1/1#员工资料查询",
            children: [
                {
                    text: "1.1员工列表",
                    path: "/home/staff/staff1/1#员工列表",
                },
                {
                    text: "1.2员工详情",
                    path: "/home/staff/staff1/1#员工详情",
                },
                {
                    text: "1.3员工考勤",
                    path: "/home/staff/staff1/1#员工考勤",
                },
                {
                    text: "1.4呼叫配置",
                    path: "/home/staff/staff1/1#呼叫配置",
                }]
        },
        {
            text: "2.添加新员工",
            path: "/home/staff/staff1/1#添加新员工",
        },
        {
            text: "3.健康打卡",
            path: "/home/staff/staff1/1#健康打卡",
        }]

    }, {
        text: "二、用户组权限",
        path: "/home/staff/staff2/1#用户组权限",
        children: [
            {
                text: "1.用户组设置",
                path: "/home/staff/staff2/1#用户组设置",
                children: [
                    {
                        text: "1.1用户组列表",
                        path: "/home/staff/staff2/1#用户组列表",
                    },
                    {
                        text: "1.2添加用户组",
                        path: "/home/staff/staff2/1#添加用户组",
                    },
                    {
                        text: "1.3设置权限",
                        path: "/home/staff/staff2/1#设置权限",
                    }]
            }]
    }, {
        text: "三、其他",
        path: "/home/staff/staff3/1#其他",
        children: [
            {
                text: "1.修改日志",
                path: "/home/staff/staff3/1#修改日志",
            },
            {
                text: "2.操作日志",
                path: "/home/staff/staff3/1#操作日志",
            },
            {
                text: "3.登录日志",
                path: "/home/staff/staff3/1#登录日志",
            },
            {
                text: "4.清除日志",
                path: "/home/staff/staff3/1#清除日志",
            },
            {
                text: "5.考勤记录",
                path: "/home/staff/staff3/1#考勤记录",
            },
            {
                text: "6.导入员工",
                path: "/home/staff/staff3/1#导入员工",
            }
        ]
    }]
}