import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import recruit from './recruit'
import student from './student'
import finance from './finance'
import staff from './staff'
import teach from './teach'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children:[
      ...recruit, // 招生  
      ...student, // 学生  
      ...finance, // 财务
      ...staff, // 员工
      ...teach // 教学
    ]
  },
  {
    path: '/',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
