const recruit = [
    {
        path: '/home/recruit/recruit0/0',
        name: 'Recruit0-0',
        component: () => import('../views/recruit/recruit0/0.vue')
    },
    {
        path: '/home/recruit/recruit0/1',
        name: 'Recruit0-1',
        component: () => import('../views/recruit/recruit0/1.vue')
    },
    {
        path: '/home/recruit/recruit0/2',
        name: 'Recruit0-2',
        component: () => import('../views/recruit/recruit0/2.vue')
    },
    {
        path: '/home/recruit/recruit0/3',
        name: 'Recruit0-3',
        component: () => import('../views/recruit/recruit0/3.vue')
    },
    {
        path: '/home/recruit/recruit0/4',
        name: 'Recruit0-4',
        component: () => import('../views/recruit/recruit0/4.vue')
    },
    {
        path: '/home/recruit/recruit0/5',
        name: 'Recruit0-5',
        component: () => import('../views/recruit/recruit0/5.vue')
    },
    {
        path: '/home/recruit/recruit1/1',
        name: 'Recruit1-1',
        component: () => import('../views/recruit/recruit1/1.vue')
    },
    {
        path: '/home/recruit/recruit2/1',
        name: 'Recruit2-1',
        component: () => import('../views/recruit/recruit2/1.vue')
    },
    {
        path: '/home/recruit/recruit3/1',
        name: 'Recruit3-1',
        component: () => import('../views/recruit/recruit3/1.vue')
    },
    {
        path: '/home/recruit/recruit4/1',
        name: 'Recruit4-1',
        component: () => import('../views/recruit/recruit4/1.vue')
    }
]

export default recruit