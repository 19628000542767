export default {
    finance: [{
        text: "一、学生缴费",
        path: "/home/finance/finance1/1#学生缴费",
        children: [{
            text: "1.财务流水",
            path: "/home/finance/finance1/1#财务流水",
            children: [
                {
                    text: "1.1财务流水列表",
                    path: "/home/finance/finance1/1#财务流水列表",
                },
                {
                    text: "1.2财务流水详情",
                    path: "/home/finance/finance1/1#财务流水详情",
                }]
        },
        {
            text: "2.续费-缴费",
            path: "/home/finance/finance1/1#续费-缴费",
            children: [
                {
                    text: "2.1续费-缴费列表",
                    path: "/home/finance/finance1/1#续费-缴费列表",
                },
                {
                    text: "2.2续费-缴费提交",
                    path: "/home/finance/finance1/1#续费-缴费提交",
                }]
        }]

    }, {
        text: "二、财务支出",
        path: "/home/finance/finance2/1#财务支出",
        children: [
            {
                text: "1.日常支出流水",
                path: "/home/finance/finance2/1#日常支出流水",
            },
            {
                text: "2.添加财务支出",
                path: "/home/finance/finance2/1#添加财务支出",
            }]
    }, {
        text: "三、申请审批",
        path: "/home/finance/finance3/1#申请审批",
        children: [
            {
                text: "1.赠课/学币审核",
                path: "/home/finance/finance3/1#赠课-学币审核",
            },
            {
                text: "2.退款审核",
                path: "/home/finance/finance3/1#退款审核",
            }
        ]
    },
    {
        text: "四、高级功能",
        path: "/home/finance/finance4/1#高级功能",
        children: [
            {
                text: "1.课时奖金结算",
                path: "/home/finance/finance4/1#课时奖金结算",
            },
            {
                text: "2.课时奖金查询",
                path: "/home/finance/finance4/1#课时奖金查询",
            },
            {
                text: "3.财务流水回收站",
                path: "/home/finance/finance4/1#财务流水回收站",
            },
            {
                text: "4.导入财务资料",
                path: "/home/finance/finance4/1#导入财务资料",
            }
        ]
    }]
}