export default {
    recruit: [{
        text: "系统搭建",
        path: "/home/recruit/recruit0/0#系统搭建",
        children: [{
            text: "系统使用前配置",
            path: "/home/recruit/recruit0/0#系统使用前配置",
        }, {
            text: "1.招生",
            path: "/home/recruit/recruit0/1#招生",
            children: [{
                text: "1.1线索渠道",
                path: "/home/recruit/recruit0/1#线索渠道",
            },
            {
                text: "1.2线索意向状态",
                path: "/home/recruit/recruit0/1#线索意向状态",
            },
            {
                text: "1.3回访联系结果",
                path: "/home/recruit/recruit0/1#回访联系结果",
            },
            {
                text: "1.4回访常用语",
                path: "/home/recruit/recruit0/1#回访常用语",
            },
            {
                text: "1.5扩展标记",
                path: "/home/recruit/recruit0/1#扩展标记",
            }]
        }, {
            text: "2.学生",
            path: "/home/recruit/recruit0/2#学生",
            children: [{
                text: "2.1回访方式",
                path: "/home/recruit/recruit0/2#年级设置",
            }]
        }, {
            text: "3.财务",
            path: "/home/recruit/recruit0/3#财务",
            children: [{
                text: "3.1学员课时费设置",
                path: "/home/recruit/recruit0/3#学员课时费设置",
            },
            {
                text: "3.2交款方式设置",
                path: "/home/recruit/recruit0/3#交款方式设置",
            },
            {
                text: "3.3教师课费标准",
                path: "/home/recruit/recruit0/3#教师课费标准",
            },
            {
                text: "3.4课时扣减规则",
                path: "/home/recruit/recruit0/3#课时扣减规则",
            },
            {
                text: "3.5财务支出项目设置",
                path: "/home/recruit/recruit0/3#财务支出项目设置",
            },
            {
                text: "3.6支出方式设置",
                path: "/home/recruit/recruit0/3#支出方式设置",
            },
            {
                text: "3.7赠课规则",
                path: "/home/recruit/recruit0/3#赠课规则",
            }]
        }, {
            text: "4.教学",
            path: "/home/recruit/recruit0/4#教学",
            children: [{
                text: "4.1年级设置",
                path: "/home/recruit/recruit0/4#年级设置",
            },
            {
                text: "4.2学科设置",
                path: "/home/recruit/recruit0/4#学科设置",
            },
            {
                text: "4.3教学模式(班型)",
                path: "/home/recruit/recruit0/4#教学模式-班型",
            },
            {
                text: "4.4考试类型设置",
                path: "/home/recruit/recruit0/4#考试类型设置",
            },
            {
                text: "4.5节假日调课设置",
                path: "/home/recruit/recruit0/4#节假日调课设置",
            }]
        }, {
            text: "5.系统",
            path: "/home/recruit/recruit0/5#系统",
            children: [
            {
                text: "5.1读卡器设置",
                path: "/home/recruit/recruit0/5#读卡器设置",
            },
            {
                text: "5.2校区设置",
                path: "/home/recruit/recruit0/5#校区设置",
            },
            {
                text: "5.3分公司设置",
                path: "/home/recruit/recruit0/5#分公司设置",
            },
            {
                text: "5.4许可IP列表设置",
                path: "/home/recruit/recruit0/5#许可IP列表设置",
            },
            {
                text: "5.5设置系统信息",
                path: "/home/recruit/recruit0/5#设置系统信息",
            },
            {
                text: "5.6学生教师平台定制",
                path: "/home/recruit/recruit0/5#学生教师平台定制",
            },
            ]
        }]
    }, {
        text: "一、线索资料管理",
        path: "/home/recruit/recruit1/1#线索资料管理",
        children: [{
            text: "1.招生线索查询",
            path: "/home/recruit/recruit1/1#招生线索查询",
            children: [
                {
                    text: "1.1线索列表",
                    path: "/home/recruit/recruit1/1#线索列表",
                },
                {
                    text: "1.2线索操作",
                    path: "/home/recruit/recruit1/1#线索操作",
                },
                {
                    text: "1.3线索回访",
                    path: "/home/recruit/recruit1/1#线索回访",
                }
            ]
        },
        {
            text: "2.添加线索资料",
            path: "/home/recruit/recruit1/1#添加线索资料",
        },
        {
            text: "3.已转正线索",
            path: "/home/recruit/recruit1/1#已转正线索",
        },
        {
            text: "4.线索公海",
            path: "/home/recruit/recruit1/1#线索公海",
            children: [
                {
                    text: "4.1公海概述",
                    path: "/home/recruit/recruit1/1#公海概述",
                },
                {
                    text: "4.2公海规则调配",
                    path: "/home/recruit/recruit1/1#公海规则调配",
                },
            ]
        },
        {
            text: "5.N天未联系",
            path: "/home/recruit/recruit1/1#N天未联系",
        },
        {
            text: "6.超时未回访",
            path: "/home/recruit/recruit1/1#超时未回访",
        }]

    }, {
        text: "二、回访跟进",
        path: "/home/recruit/recruit2/1#回访跟进",
        children: [
            {
                text: "1.今日预约线索",
                path: "/home/recruit/recruit2/1#今日预约线索",
            },
            {
                text: "2.今日已联系",
                path: "/home/recruit/recruit2/1#今日已联系",
            },
            {
                text: "3.近期预约线索",
                path: "/home/recruit/recruit2/1#近期预约线索",
            },
            {
                text: "4.近期已联系",
                path: "/home/recruit/recruit2/1#近期已联系",
            },
            {
                text: "5.来访登记查询",
                path: "/home/recruit/recruit2/1#来访登记查询",
            },
            {
                text: "6.回访记录查询",
                path: "/home/recruit/recruit2/1#回访记录查询",
            },
            {
                text: "7.线索电话记录",
                path: "/home/recruit/recruit2/1#线索电话记录",
            },
        ],
    }, {
        text: "三、高级功能",
        path: "/home/recruit/recruit3/1#高级功能",
        children: [
            {
                text: "1.我的战绩",
                path: "/home/recruit/recruit3/1#我的战绩",
            },
            {
                text: "2.将学员导入线索",
                path: "/home/recruit/recruit3/1#将学员导入线索",
            },
            {
                text: "3.线索升级",
                path: "/home/recruit/recruit3/1#线索升级",
            },
            {
                text: "4.导入线索资料",
                path: "/home/recruit/recruit3/1#导入线索资料",
            },
            {
                text: "5.操作日志查询",
                path: "/home/recruit/recruit3/1#操作日志查询",
            }
        ]
    },
    {
        text: "四、调配规则",
        path: "/home/recruit/recruit4/1#调配规则",
        children: [
            {
                text: "1.线索回收规则",
                path: "/home/recruit/recruit4/1#线索回收规则",
            },
            {
                text: "2.线索领取规则",
                path: "/home/recruit/recruit4/1#线索领取规则",
            },
            {
                text: "3.线索锁定规则",
                path: "/home/recruit/recruit4/1#线索锁定规则",
            }
        ]
    }]
}