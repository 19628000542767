export default {
    student: [{
        text: "一、资料查询",
        path: "/home/student/student1/1#资料查询",
        children: [{
            text: "1.学生资料查询",
            path: "/home/student/student1/1#学生资料查询",
            children: [
                {
                    text: "1.1学生资料查询列表",
                    path: "/home/student/student1/1#学生资料查询列表",
                },
                {
                    text: "1.2学生资料详情",
                    path: "/home/student/student1/1#学生资料详情",
                },
                {
                    text: "1.3学生回访记录",
                    path: "/home/student/student1/1#学生回访记录",
                }
            ]
        },
        {
            text: "2.添加学生资料",
            path: "/home/student/student1/1#添加学生资料",
        }]

    }, {
        text: "二、学生课费",
        path: "/home/student/student2/1#学生课费",
        children: [
            {
                text: "1.停课预警",
                path: "/home/student/student2/1#停课预警",
            },
            {
                text: "2.课费预警",
                path: "/home/student/student2/1#课费预警",
            },
            {
                text: "3.学生报班查询",
                path: "/home/student/student2/1#学生报班查询",
            },
            {
                text: "4.学生订课查询",
                path: "/home/student/student2/1#学生订课查询",
            }]
    }, {
        text: "三、学生关怀",
        path: "/home/student/student3/1#学生关怀",
        children: [
            {
                text: "1.预约记录",
                path: "/home/student/student3/1#预约记录",
            },
            {
                text: "2.回访记录",
                path: "/home/student/student3/1#回访记录",
            },
            {
                text: "3.学生回访查询",
                path: "/home/student/student3/1#学生回访查询",
            },
            {
                text: "4.N天未回访",
                path: "/home/student/student3/1#N天未回访",
            },
            {
                text: "5.近期生日的学生",
                path: "/home/student/student3/1#近期生日的学生",
            },
            {
                text: "6.家长会查询",
                path: "/home/student/student3/1#家长会查询",
            },
            {
                text: "7.成绩查询",
                path: "/home/student/student3/1#成绩查询",
                children: [
                    {
                        text: "7.1成绩查询列表",
                        path: "/home/student/student3/1#成绩查询列表",
                    },
                    {
                        text: "7.2成绩详情",
                        path: "/home/student/student3/1#成绩详情",
                    }
                ]
            },
            {
                text: "8.请假记录查询",
                path: "/home/student/student3/1#请假记录查询",
            },
            {
                text: "9.学员通话记录",
                path: "/home/student/student3/1#学员通话记录",
            }
        ]
    },
    {
        text: "四、申请单",
        path: "/home/student/student4/1#申请单",
        children: [
            {
                text: "1.赠课/学币申请",
                path: "/home/student/student4/1#赠课-学币申请",
                children: [
                    {
                        text: "1.1赠课/学币列表",
                        path: "/home/student/student4/1#赠课-学币列表",
                    },
                    {
                        text: "1.2申请赠课/学币",
                        path: "/home/student/student4/1#申请赠课-学币",
                    }
                ]
            },
            {
                text: "2.退款申请",
                path: "/home/student/student4/1#退款申请",
                children: [
                    {
                        text: "2.1退款申请列表",
                        path: "/home/student/student4/1#退款申请列表",
                    },
                    {
                        text: "2.2提交退款申请",
                        path: "/home/student/student4/1#提交退款申请",
                    }
                ]
            }
        ]
    }, {
        text: "五、高级功能",
        path: "/home/student/student5/1#高级功能",
        children: [
            {
                text: "1.学管带管人数",
                path: "/home/student/student5/1#学管带管人数",

            },
            {
                text: "2.历史剩余课费",
                path: "/home/student/student5/1#历史剩余课费",

            },
            {
                text: "3.修改日志",
                path: "/home/student/student5/1#修改日志",

            },
            {
                text: "4.学生升级",
                path: "/home/student/student5/1#学生升级",

            },
            {
                text: "5.导入学生资料",
                path: "/home/student/student5/1#导入学生资料",

            }
        ]
    }]
}