const student = [
    {
        path: '/home/student/student1/1',
        name: 'Student1-1',
        component: () => import('../views/student/student1/1.vue')
    },
    {
        path: '/home/student/student2/1',
        name: 'Student2-1',
        component: () => import('../views/student/student2/1.vue')
    },
    {
        path: '/home/student/student3/1',
        name: 'Student3-1',
        component: () => import('../views/student/student3/1.vue')
    },
    {
        path: '/home/student/student4/1',
        name: 'Student4-1',
        component: () => import('../views/student/student4/1.vue')
    },
    {
        path: '/home/student/student5/1',
        name: 'Student5-1',
        component: () => import('../views/student/student5/1.vue')
    }
]

export default student