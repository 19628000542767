export default {
	teach: [{
			text: "一、课表/课耗",
			path: "/home/teach/teach1/1#课表-课耗",
			children: [{
					text: "1.排课记录-扣费",
					path: "/home/teach/teach1/1#排课记录-扣费",
					children: [{
							text: "1.1排课记录",
							path: "/home/teach/teach1/1#排课记录",
						},
						{
							text: "1.2排课修改",
							path: "/home/teach/teach1/1#排课修改",
						},
						{
							text: "1.3加入新学员",
							path: "/home/teach/teach1/1#加入新学员",
						},
						{
							text: "1.4课时补录",
							path: "/home/teach/teach1/1#课时补录",
						}
					]
				},
				{
					text: "2.上课记录管理",
					path: "/home/teach/teach1/1#上课记录管理",
				},
				{
					text: "3.教师已排课程",
					path: "/home/teach/teach1/1#教师已排课程",
				},
				{
					text: "4.上课记录回收站",
					path: "/home/teach/teach1/1#上课记录回收站",
				}
			]

		}, {
			text: "二、班课",
			path: "/home/teach/teach2/1#班课",
			children: [{
					text: "1.班级管理",
					path: "/home/teach/teach2/1#班级管理",
					children: [{
							text: "1.1班级列表",
							path: "/home/teach/teach2/1#班级列表",
						}, {
							text: "1.2班级详细",
							path: "/home/teach/teach2/1#班级详细",
						},
						{
							text: "1.3班级排课",
							path: "/home/teach/teach2/1#班级排课",
						}
					]
				},
				{
					text: "2.创建班级",
					path: "/home/teach/teach2/1#创建班级",
				},
				{
					text: "3.教室管理",
					path: "/home/teach/teach2/1#教室管理",
					children: [{
							text: "3.1教室列表",
							path: "/home/teach/teach2/1#教室列表",
						}, {
							text: "3.2教室排课情况",
							path: "/home/teach/teach2/1#教室排课情况",
						}
					]
				},
				{
					text: "4.创建教室",
					path: "/home/teach/teach2/1#创建教室",
				}
			]
		}, {
			text: "三、一对一 一对二",
			path: "/home/teach/teach3/1#一对一-一对二",
			children: [{
					text: "1.按周排课",
					path: "/home/teach/teach3/1#按周排课",
					children: [{
							text: "1.1学员排课列表",
							path: "/home/teach/teach3/1#学员排课列表",
						}, {
							text: "1.2排课",
							path: "/home/teach/teach3/1#排课",
						},
						{
							text: "1.3课程表",
							path: "/home/teach/teach3/1#课程表",
						}
					]
				},
				{
					text: "2.按周排课(新)",
					path: "/home/teach/teach3/1#按周排课-新",
				},
				{
					text: "3.按日期排课",
					path: "/home/teach/teach3/1#按日期排课",
				},
				{
					text: "4.排课单打印",
					path: "/home/teach/teach3/1#排课单打印",
				}
			]
		},
		{
			text: "四、课程预定",
			path: "/home/teach/teach4/1#课程预定1",
			children: [{
					text: "1.课程预定",
					path: "/home/teach/teach4/1#课程预定2",
					children: [{
							text: "1.1课程预定列表",
							path: "/home/teach/teach4/1#课程预定列表",
						}, {
							text: "1.2课程修改",
							path: "/home/teach/teach4/1#课程修改",
						},
						{
							text: "1.3课程调整",
							path: "/home/teach/teach4/1#课程调整",
						},
						{
							text: "1.4课程分析",
							path: "/home/teach/teach4/1#课程分析",
						}
					]
				},
				{
					text: "2.赠课/学币管理",
					path: "/home/teach/teach4/1#赠课-学币管理",
				},
				{
					text: "3.课程预定日志",
					path: "/home/teach/teach4/1#课程预定日志",
				},
			]
		},
		{
			text: "五、高级功能",
			path: "/home/teach/teach5/1#高级功能",
			children: [{
					text: "1.教案查询",
					path: "/home/teach/teach5/1#教案查询",
				},
				{
					text: "2.课费升级",
					path: "/home/teach/teach5/1#课费升级",
				}
			]
		}
	]
}
