<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- 移动端抽屉菜单 -->
    <el-drawer
      title="菜单目录"
      :visible.sync="drawer"
      direction="ltr"
      :modal="false"
      :before-close="drawerSwitch"
    >
      <aside class="home-aside" style="display: block">
        <el-menu
          default-active="1"
          class="home-aside-demo"
          :unique-opened="true"
          @open="handleSelect"
          @select="handleSelect"
        >
          <template v-for="item in list">
            <el-submenu
              :index="item.path"
              v-if="item.children"
              :key="item.path"
            >
              <template slot="title">
                <span>{{ item.text }}</span>
              </template>
              <template v-for="children1 in item.children">
                <el-submenu
                  :index="children1.path"
                  v-if="children1.children"
                  :key="children1.path"
                >
                  <template slot="title">{{ children1.text }}</template>
                  <template v-for="children2 in children1.children">
                    <el-submenu
                      :index="children2.path"
                      v-if="children2.children"
                      :key="children2.path"
                    >
                      <template slot="title">{{ children2.text }}</template>
                      <el-menu-item
                        v-for="children3 in children2.children"
                        :index="children3.path"
                        :key="children3.path"
                        >{{ children3.text }}</el-menu-item
                      >
                    </el-submenu>
                    <el-menu-item
                      :index="children2.path"
                      :key="children2.path"
                      v-else
                      >{{ children2.text }}</el-menu-item
                    >
                  </template>
                </el-submenu>
                <el-menu-item
                  :index="children1.path"
                  v-else
                  :key="children1.path"
                  >{{ children1.text }}</el-menu-item
                >
              </template>
            </el-submenu>
            <el-menu-item :index="item.path" v-else :key="item.path">
              <span slot="title">{{ item.text }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </aside>
    </el-drawer>

    <!-- pc固定左侧菜单 -->
    <aside class="home-aside">
      <el-menu
        default-active="1"
        class="home-aside-demo"
        :unique-opened="true"
        @open="handleSelect"
        @select="handleSelect"
      >
        <template v-for="item in list">
          <el-submenu :index="item.path" v-if="item.children" :key="item.path">
            <template slot="title">
              <span>{{ item.text }}</span>
            </template>
            <template v-for="children1 in item.children">
              <el-submenu
                :index="children1.path"
                v-if="children1.children"
                :key="children1.path"
              >
                <template slot="title">{{ children1.text }}</template>
                <template v-for="children2 in children1.children">
                  <el-submenu
                    :index="children2.path"
                    v-if="children2.children"
                    :key="children2.path"
                  >
                    <template slot="title">{{ children2.text }}</template>
                    <el-menu-item
                      v-for="children3 in children2.children"
                      :index="children3.path"
                      :key="children3.path"
                      >{{ children3.text }}</el-menu-item
                    >
                  </el-submenu>
                  <el-menu-item
                    :index="children2.path"
                    :key="children2.path"
                    v-else
                    >{{ children2.text }}</el-menu-item
                  >
                </template>
              </el-submenu>
              <el-menu-item
                :index="children1.path"
                v-else
                :key="children1.path"
                >{{ children1.text }}</el-menu-item
              >
            </template>
          </el-submenu>
          <el-menu-item :index="item.path" v-else :key="item.path">
            <span slot="title">{{ item.text }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </aside>
    <div class="home-content">
      <router-view />
    </div>
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
import menu from '../utils/menu/index'
import { mapMutations } from "vuex";
export default {
  name: "Home",
  // components: {
  //   HelloWorld
  // }
  data() {
    return {
      list: []
    };
  },
  computed: {
    drawer() {
      return this.$store.state.drawer;
    },
    type() {
      return this.$store.state.type;
    },
  },
  mounted() {
    this.list = menu[this.type];
    this.$router.replace(this.list[0].path)
  },
  methods: {
    ...mapMutations(["drawerSwitch"]),
    handleSelect(index) {
      if (this.$route.fullPath != index) {
        this.$router.replace(index);
      }
    },
  },
};
</script>
<style scoped>
.home {
  display: flex;
  justify-content: flex-end;
}
.home-aside {
  width: 20rem;
  flex-shrink: 0;
  position: fixed;
  left: 0;
  top: 61px;
}
.home-aside-demo {
  height: calc(100vh - 61px);
  overflow: auto;
}
.home-content {
  width: calc(100vw - 20rem);
  height: calc(100vh - 61px);
  overflow: auto;
  scroll-behavior: smooth;
}
@media screen and (max-width: 720px) {
  .home-aside {
    width: 65%;
    display: none;
  }
  .home-content {
    width: 100vw;
  }
}
</style>
