const teach = [{
		path: '/home/teach/teach1/1',
		name: 'Teach1-1',
		component: () => import('../views/teach/teach1/1.vue')
	},
	{
		path: '/home/teach/teach2/1',
		name: 'Teach2-1',
		component: () => import('../views/teach/teach2/1.vue')
	},
	{
		path: '/home/teach/teach3/1',
		name: 'Teach3-1',
		component: () => import('../views/teach/teach3/1.vue')
	},
	{
		path: '/home/teach/teach4/1',
		name: 'Teach4-1',
		component: () => import('../views/teach/teach4/1.vue')
	},
	{
		path: '/home/teach/teach5/1',
		name: 'Teach5-1',
		component: () => import('../views/teach/teach5/1.vue')
	}
]

export default teach
